<script setup lang="ts">
import { fromNow } from 'assets/js/filters/dayjs';
import VBadge from '~/components/new/VBadge.vue';
import type { Project } from '~/stores/Project.types';
import type { EntityType } from '~/enitityHelper';
import { entities } from '~/enitityHelper';
interface ProjectCardProps {
  projectCard: Project;
  schema?: 'default' | 'pro' | 'pro-white';
  showHeader?: boolean;
}

const props = withDefaults(defineProps<ProjectCardProps>(), {
  schema: 'default',
  showHeader: true,
});

const texts = entities[(props.projectCard.entity as EntityType) || 'project'];
const time = useState('time', () => fromNow(props.projectCard.created_at));
</script>

<template>
  <nuxt-link
    :to="`/projects/${projectCard.slug || projectCard.id}`"
    class="project-card"
  >
    <div
      v-show="showHeader"
      class="project-card__group-tag"
    >
      <div class="project-card__tag">
        {{ texts.name }}
      </div>
      <v-badge
        v-if="projectCard.payed_status"
        :schema="'primary'"
      >
        PRO
      </v-badge>
    </div>
    <h5>{{ projectCard.title }}</h5>
    <div class="project-card__text">
      {{ projectCard.description }}
    </div>

    <client-only>
      <div class="project-card__time">
        {{ fromNow(props.projectCard.created_at).replace(/ /g, '&nbsp;') }}
      </div>
    </client-only>
  </nuxt-link>
</template>

<style scoped lang="scss">
.project-card {
  display: flex;
  background: white;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  min-height: 340px;

  @include media-breakpoint-down(md) {
    min-height: 160px;
  }

  h5 {
    margin-bottom: 16px;
    color: $new-fr-contrast;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-wrap: balance;
    hyphens: auto;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      -webkit-line-clamp: 6;
    }
  }

  &:hover {
    @include media-breakpoint-up(md) {
      transform: translateY(-4px);
      box-shadow: $new-card-shadow;
    }
  }

  .project-card__group-tag {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    margin-bottom: 12px;
  }

  .project-card__tag {
    color: $new-fr-theme;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .project-card__text {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgba($new-fr-contrast, 0.3);
    // margin-top: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 16px;
    hyphens: auto;
    overflow-wrap: break-word;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .project-card__time {
    margin-top: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: rgba($new-fr-contrast, 0.3);
    hyphens: auto;
  }
}
</style>
