<script setup lang="ts">
interface BadgeProps {
  schema?: 'default' | 'secondary' | 'primary';
  outline?: boolean;
}

const props = withDefaults(defineProps<BadgeProps>(), {
  outline: false,
  schema: 'default',
});
const cClass = computed(() => {
  const res = [];
  if (props.schema) {
    res.push('badge_' + props.schema);
  }
  if (props.outline) {
    res.push('badge_outline');
  }
  return res.join(' ');
});
</script>

<template>
  <div
    class="badge"
    :class="cClass"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.badge {
  display: inline-block;
  color: white;
  background: $new-fr-theme;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
  padding: 5px 6px;
  border-radius: 14px;
}

.badge_outline {
  border: 1px solid $new-line-stroke;
}

.badge_secondary {
  background: $new-bg-theme-fade;
  color: $new-fr-contrast;
}
.badge_primary {
  color: white;
  background: $new-fr-theme;
}

.badge_default {
  color: white;
  background: $new-fr-theme;
}
</style>
